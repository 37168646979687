import { Height } from "@material-ui/icons";
import React from "react";
import facebook from "../../../assets/SocialMedia/facebook.png";
import linkedIn from "../../../assets/SocialMedia/linkedin.png";
import youtube from "../../../assets/SocialMedia/youtube.png";
import insta from "../../../assets/SocialMedia/instagram.png";
import website from "../../../assets/SocialMedia/website.png";

function AddLinks({ data, handleChange }) {
  return (
    <div className="row justify-center">
      <div className="col-md-8">
        <form action="">
          <div className="form-group">
            <img
              src={facebook}
              alt="facebook"
              className="social__logo__inform"
            />
            <label className="text-light">Facebook url...</label>
            <input
              className="form-control padding__left__60px"
              placeholder="Facebook url..."
              value={data.facebookurl}
              onChange={handleChange("facebookurl")}
              type="text"
            />
          </div>
          <div className="form-group">
            <img src={insta} alt="instagram" className="social__logo__inform" />
            <label className="text-light">Instagram url...</label>
            <input
              className="form-control padding__left__60px"
              placeholder="Instagram url..."
              value={data.instagramurl}
              onChange={handleChange("instagramurl")}
              type="text"
            />
          </div>
          <div className="form-group">
            <img
              src={linkedIn}
              alt="linkedin"
              className="social__logo__inform"
            />
            <label className="text-light">LinkedIn url...</label>
            <input
              className="form-control padding__left__60px"
              placeholder="LinkedIn url..."
              value={data.linkedinurl}
              onChange={handleChange("linkedinurl")}
              type="text"
            />
          </div>
          <div className="form-group">
            <img src={youtube} alt="youtube" className="social__logo__inform" />
            <label className="text-light">Youtube url...</label>
            <input
              className="form-control padding__left__60px"
              placeholder="Youtube url..."
              value={data.youtubeurl}
              onChange={handleChange("youtubeurl")}
              type="text"
            />
          </div>
          <div className="form-group">
            <img src={website} alt="website" className="social__logo__inform" />
            <label className="text-light">Facebook url...</label>
            <input
              className="form-control padding__left__60px"
              placeholder="Website url..."
              value={data.websiteurl}
              onChange={handleChange("websiteurl")}
              type="text"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddLinks;
