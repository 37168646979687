import React, { useState, useEffect } from "react";
import "./Section.scss";

function AboutYourself({ data, handleChange }) {
  // console.log("About Ypurself props", props);

  return (
    <div className="row justify-center">
      <div className="col-md-8">
        <form>
          <h5 className="form__heading">Name</h5>
          <div style={{ height: "10px" }} />
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Name"
              onChange={handleChange("name")}
              type="text"
              value={data.name}
              maxLength={25}
            />
          </div>
          <div style={{ height: "15px" }} />
          <h5 className="form__heading">About yourself</h5>
          <div style={{ height: "10px" }} />
          <div className="form-group">
            <textarea
              style={{ height: "150px" }}
              className="form-control"
              placeholder="About yourself"
              type="text"
              value={data.aboutYourself}
              onChange={handleChange("aboutYourself")}
              maxLength={1000}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default AboutYourself;
