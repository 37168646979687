import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  VideoPercentageContainer,
  VideoUploadContainer,
  UploadVideoPercentage,
} from "../../StyleConfig/Common/CommonStyles";
import Theme from "../../StyleConfig/Theme";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: "100%",
    borderRadius: 5,
    padding: 10,
    width: "100%",
  },
  colorPrimary: {
    backgroundColor: "rgba(113, 198, 255, 0.28);",
    //   theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "rgba(113, 198, 255, 100);",
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function AppProgressBar({ percentage }) {
  const classes = useStyles();
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {}, [percentage]);

  return (
    <>
      <VideoUploadContainer>
        <VideoPercentageContainer
          background={Theme.LIGHT.UPLOADPERCENTAGEBACKGROUND}
        >
          <UploadVideoPercentage>{percentage}%</UploadVideoPercentage>
        </VideoPercentageContainer>
        <div
          className="flex-column"
          style={{ marginLeft: "5px", width: "100%" }}
        >
          <BorderLinearProgress variant="determinate" value={percentage} />
        </div>
      </VideoUploadContainer>
    </>
  );
}

export default AppProgressBar;
