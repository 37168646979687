import React from "react";
import Rating from "@material-ui/lab/Rating";

function Ratings({ data, handleChange }) {
  return (
    <div>
      <Rating
        name="simple-controlled"
        value={data.courserating}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        style={{ borderRadius: "4px" }}
      />
    </div>
  );
}

export default Ratings;

export const DisplayRatings = ({ rating }) => {
  return (
    <Rating
      style={{ borderRadius: "4px" }}
      name="Course Rating"
      value={rating}
      readOnly
    />
  );
};
