import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";
import { forgetpassword } from "../../auth/helper";
import { SaveButton } from "../../StyleConfig/Common/CommonStyles";
import Loader from "../Loader/Loader";
import "./ForgetPassword.scss";

function ForgetPassword({ setLoading }) {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const onsubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    forgetpassword(email)
      .then((res) => {
        console.log("Check Forget Password Response", res);
        setLoading(false);
        if (res.status === true) {
          setResponseMessage("Reset password link has been send to email.");
          setSuccess(true);
          setTimeout(() => {
            history.push("/signin");
          }, 2000);
        }
      })
      .catch((error) => {
        console.log("Check Error for forget password", error);
        setLoading(false);
      });
  };

  return (
    <div className="forgetpassword">
      <div className="container">
        <div className="row justify-center">
          <div className="col-md-6 col-sm-12">
            <h4 className="text-center">Please enter email</h4>
            <form onSubmit={onsubmit}>
              <div className="form-group">
                <label className="text-light">Email</label>
                <input
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  value={email}
                  required
                />
                <div style={{ height: "20px" }} />
                <div className="justify-center">
                  <button className="btn btn-info btn-block signupform__button">
                    <span>Continue</span>
                  </button>
                </div>
              </div>
            </form>
            <div style={{ height: "5px" }} />
            <Link
              to={{
                pathname: "/signin",
              }}
            >
              <h6 className="flex-end apptext__color">Continue to sign in?</h6>
            </Link>
          </div>
        </div>
      </div>
      {success && <AppSnackbar message={responseMessage} severity="success" />}
    </div>
  );
}

export default Loader(ForgetPassword);
