import React from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import logo from "../../assets/Logo/skilllight2.png";
import { useDataLayerValue } from "../../context/DataLayer";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import MediaQuery from "react-responsive";
import "./AppHeader.scss";

function AppHeader(props) {
  const [{}, dispatch] = useDataLayerValue();
  const loginuserid = JSON.parse(localStorage.getItem("userId"));

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <Link
        className="navbar-brand"
        to={{
          pathname: "/home",
        }}
      >
        <img src={logo} alt="skill'ohana" width="150px" className="applogo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse flex-end" id="navbarNav">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <NavLink
              to={{
                pathname: "/home",
              }}
              activeClassName="nav__active"
              className="nav-link"
            >
              <span>Home</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to={{
                pathname: "/courses/explore",
              }}
              activeClassName="nav__active"
              className="nav-link"
            >
              <span>Courses</span>
            </NavLink>
          </li>
          <MediaQuery maxWidth={991}>
            <li className="nav-item">
              <NavLink
                to={{
                  pathname: "/courses/recommend",
                }}
                activeClassName="nav__active"
                className="nav-link"
              >
                <span>Recommended</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={{
                  pathname: "/courses/recent",
                }}
                activeClassName="nav__active"
                className="nav-link"
              >
                <span>Recent</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={{
                  pathname: "/courses/publish",
                }}
                activeClassName="nav__active"
                className="nav-link"
              >
                <span>Publish</span>
              </NavLink>
            </li>
          </MediaQuery>
          <li className="nav-item">
            <Link
              className="nav-link nav-avatar"
              to={{
                pathname: "/myprofile",
              }}
              onClick={() => {
                dispatch({
                  profileuserid: loginuserid,
                  type: "SET__PROFILEUSER__ID",
                });
              }}
            >
              <img
                src={
                  IMAGECONFIG.IMAGECONFIG.PROFILE +
                  JSON.parse(localStorage.getItem("mediaId"))
                }
                alt="Avatar"
                className="avatar"
                style={{
                  objectFit: "contain",
                }}
              />{" "}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default AppHeader;
