import styled, { keyframes } from "styled-components";

export const keyFrameExampleOne = keyframes`
   0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
`;
