/*
 * Application API Calls
 */
import axios from "axios";
import { API } from "../backend";

/**
 *
 * @param {*} data (Getting user information after login)
 * @returns (Response with success / failure)
 */

export const askaboutinfo = (data) => {
  console.log("ask about data to be submit", data);
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("about_me", data.aboutYourself);
  formData.append("interest", data.interest);
  formData.append("achievement", data.achievements);
  formData.append("img", data.profilepic);
  formData.append("profession", data.teacher === true ? "Teacher" : "Student");
  formData.append("online_learning", data.learntoteachonline);
  formData.append("teaching_kind", data.teachingdonebefore);
  formData.append("video_pro", data.videopro);
  formData.append("share_course", data.audiencetoshare);
  formData.append("facebook", data.facebookurl);
  formData.append("linkdin", data.linkedinurl);
  formData.append("instagram", data.instagramurl);
  formData.append("youtube", data.youtubeurl);
  formData.append("website", data.websiteurl);
  formData.append("media_id", data.mediaid);
  formData.append("prev_img", "");
  formData.append("user_id", data.userid);
  formData.append("id", data.userid);
  formData.append("api_token", data.apitoken);

  return axios
    .post(API + "/profile_submit", formData, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Interest Tags Api passing user_id, media_id, token)
 * @returns (Response with success / failure)
 */

export const gettingtags = (data) => {
  let getTag = `${API}/category?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .get(getTag, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (My courses (login user) courses Api)
 * @param {*} data (User Id and Token)
 * @returns (Response with my courses list of user)
 */

export const getmycourses = (data) => {
  console.log("Get My Courses Api Data", data);
  let getCourses = `${API}/my_courses?api_token=${data.token}&user_id=${
    data.userid
  }&categoryName=${data.cat_id ? data.cat_id : ""}`;
  return axios
    .get(getCourses, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const getexplorecourses = (data) => {
  console.log("Get explore course api data", data);
  let getCourses = `${API}/explore_courses?api_token=${data.token}&user_id=${
    data.userid
  }&categoryName=${data.cat_id ? data.cat_id : ""}`;
  return axios
    .get(getCourses, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Check Explore course response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Recommended Courses for home page)
 * @param {*} data (User Id and Token)
 * @returns (Response with recommended course list)
 */

export const getrecommendedcourses = (data) => {
  let getCourses = `${API}/recommend_courses?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .get(getCourses, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Recent Courses for home page)
 * @param {*} data (User Id and Token)
 * @returns (Response with recent course list)
 */

export const getrecentcourses = (data) => {
  let getCourses = `${API}/recent_courses?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .get(getCourses, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Publish Courses for home page)
 * @param {*} data (User Id and Token)
 * @returns (Response with publish course list)
 */

export const getpublishcourses = (data) => {
  let getCourses = `${API}/publish_courses?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .get(getCourses, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Home Page News feeds Api)
 */

export const getnewsfeeds = (data) => {
  console.log("Check News Feed Data to pass ---->>>>", data);
  let getNews = `${API}/activity?api_token=${data.token}&user_id=${data.userid}&page=${data.page}&size=${data.size}`;
  return axios
    .get(getNews, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Home Page Reviews API)
 * @returns (Response with success / failure)
 */
export const getreviewshomepage = (data) => {
  let getReviews = `${API}/activity?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .get(getReviews, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};
/**
 *
 * @param {*} data (Login User API)
 * @returns (Response with success / failure)
 */
export const getloginuserprofile = (data) => {
  console.log("check profile api call", data);
  let id = data.id ? data.id : "";
  let getLoginProfile = `${API}/profile?api_token=${data.token}&user_id=${data.userid}&id=${id}`;
  return axios
    .get(getLoginProfile, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 *
 * @param {*} data (Follow / Unfollow Api)
 * @returns (Response with success / failure)
 */
export const followunfollow = (data) => {
  console.log("follow/unfollow", data);
  var formData = new FormData();
  formData.append("follower_userid", data.follower_userid);
  let getFollowUnfollow = `${API}/${data.status}?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .post(getFollowUnfollow, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Get Follow UnFollow", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};
/**
 *
 * @param {*} data (Edit Login User Profile Api)
 * @returns (Response with success / failure)
 */
export const editloginuserprofile = (data) => {
  console.log("Edit login user", data);
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("img", data.uploadprofilepic);
  formData.append("subject", data.designation);
  formData.append("location", data.userlocation);
  formData.append("prev_img", data.imagestatus);
  formData.append("media_id", data.media_id);
  formData.append("about_me", data.aboutme);
  formData.append("facebook", data.fburl);
  formData.append("instagram", data.instagram);
  formData.append("linkdin", data.linkdin);
  formData.append("youtube", data.youtube);
  formData.append("website", data.websiteurl);
  formData.append("api_token", data.token);
  formData.append("user_id", data.userid);
  return axios
    .post(API + "/my_profile_update", formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Get Edit profile response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

/**
 * Upload Course Lectures Api
 * @param {*} data (Course Details)
 * @param {*} config (Progress Percentage)
 * @returns (Response with success / failure)
 */

export const uploadcourselecture = (data, config) => {
  console.log("Check Data for upload lecture", data);
  var formData = new FormData();
  formData.append("title", data.lectureTitle);
  formData.append("duration", data.lectureDuration);
  formData.append("file[]", data.lectureVideo);
  formData.append("courses_id", data.courses_id);
  formData.append("temp_code", data.id);
  formData.append("api_token", data.token);
  formData.append("user_id", data.userid);

  return axios
    .post(API + "/add_courses_sections", formData, config)
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

/**
 * Get uploaded lecture for course
 * @param {*} data (token, userid, temp_code, course_id)
 * @returns (Response with success / failure)
 */
export const getuploadlecturestocreatecourse = (data) => {
  console.log("Check Data passing for get lectures", data);
  let getlectures = `${API}/fetch_courses_sections?api_token=${data.token}&user_id=${data.userid}&temp_code=${data.temp_code}&courses_id=${data.courses_id}`;
  console.log("Check Get Lectures API", getlectures);
  return axios
    .get(getlectures)
    .then((res) => {
      console.log("Get Lectures Response", res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

/**
 * Get user interest api
 * @param {*} data
 * @returns
 */

export const getuserinterest = (data) => {
  let getuserinterest = `${API}/fetch_selected_interest?api_token=${data.token}&user_id=${data.userid}`;
  return axios
    .get(getuserinterest)
    .then((res) => {
      console.log("Get User Interests Response", res);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

/**
 * Create course api
 * @param {*} data (Details for create course)
 * @returns (Response with success / failure)
 */

export const createcourse = (data) => {
  console.log("Check create course api data to pass", data);
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("img", data.img);
  formData.append("info", data.info);
  formData.append("userid", data.user_id);
  formData.append("user_id", data.user_id);
  formData.append("cat_id", data.cat_id);
  formData.append("duration", data.duration);
  formData.append("api_token", data.api_token);
  formData.append("temp_code", data.temp_code);
  return axios
    .post(API + "/add_courses", formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Check Add course response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Check Add course error", err);

      throw err;
    });
};

export const getcoursedetails = (data) => {
  var formData = new FormData();
  formData.append("api_token", data.api_token);
  formData.append("user_id", data.user_id);
  formData.append("courses_id", data.id);

  return axios
    .post(API + "/courses_fetch", formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Check course fetch response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Check course fetch error", err);
      throw err;
    });
};

/**
 * (Get Key word search course)
 * @param {*} data (Keyword)
 * @returns (response with success / failure)
 */

export const getkeywordsearch = (data, cancelToken) => {
  console.log("Check the cancel token", cancelToken);
  console.log("Get explore course api data", data);
  let getCourses = `${API}/explore_keyword?api_token=${data.token}&user_id=${
    data.userid
  }&keyword=${data.keyword ? data.keyword : ""}`;
  return axios
    .get(
      getCourses,
      { cancelToken: cancelToken.token },
      {
        headers: { "content-type": "application/x-www-form-urlencoded" },
      }
    )
    .then((res) => {
      console.table(res.data);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const ondeletelecture = (data) => {
  console.log("Check Delete lecture data", data);
  var formData = new FormData();
  formData.append("api_token", data.api_token);
  formData.append("user_id", data.user_id);
  formData.append("sections_id", data.sections_id);

  return axios
    .post(API + "/delete_courses_sections", formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Check delete lecture response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Check delete lecture error", err);
      throw err;
    });
};

export const courselecturedit = (data, config) => {
  console.log("Course Lecture Edit", data);
  var formData = new FormData();
  formData.append("title", data.lectureTitle);
  formData.append("duration", data.lectureDuration);
  formData.append("file[]", data.lectureVideo);
  formData.append("courses_id", data.courses_id);
  formData.append("id", data.id);
  formData.append("prev_path", data.prev_path);
  formData.append("api_token", data.token);
  formData.append("user_id", data.userid);

  return axios
    .post(API + "/courses_sections_edit", formData, config)
    .then((res) => {
      console.log(res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const editcourse = (data) => {
  console.log("Check edit course api data to pass", data);
  var formData = new FormData();
  formData.append("name", data.name);
  formData.append("desc", data.info);
  formData.append("userid", data.user_id);
  formData.append("user_id", data.user_id);
  formData.append("api_token", data.api_token);
  formData.append("cat_id", data.cat_id);
  formData.append("duration", data.duration);
  formData.append("img", data.img);

  formData.append("prev_img", data.prev_img);
  formData.append("courses_id", data.courses_id);
  formData.append("media_id", data.media_id);

  return axios
    .post(API + "/courses_edit", formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Check edit response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Check edit error", err);

      throw err;
    });
};

export const getcoursereview = (data) => {
  let getCoursesReview = `${API}/fetch_courses_rating?api_token=${data.api_token}&user_id=${data.user_id}&courses_id=${data.id}`;

  return axios
    .get(getCoursesReview)
    .then((res) => {
      console.log("Get Courses Review", res.data);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const addcoursereview = (data) => {
  console.log("Check data for add review:", data);
  let addCourseReview = `${API}/add_courses_rating?api_token=${data.api_token}&user_id=${data.user_id}`;
  var formData = new FormData();
  formData.append("rating", data.rating);
  formData.append("review", data.review);
  formData.append("review_from", data.review_from);
  formData.append("review_from_name", data.review_from_name);
  formData.append("review_to", data.review_to);
  formData.append("courses_id", data.courses_id);
  formData.append("media_id", data.media_id);

  return axios
    .post(addCourseReview, formData, {
      headers: { "content-type": "application/x-www-form-urlencoded" },
    })
    .then((res) => {
      console.log("Get Add Courses Review", res.data);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const ondeletecourse = (data) => {
  console.log("Check Delete Course data", data);
  let getCoursesDelete = `${API}/delete_course?api_token=${data.api_token}&user_id=${data.user_id}&courses_id=${data.courses_id}`;

  return axios
    .post(getCoursesDelete)
    .then((res) => {
      console.log("Check delete course response", res);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      console.log("Check delete course error", err);
      throw err;
    });
};

export const addwatchlist = (data) => {
  console.log("Check Add watch list api data", data);
  let addWatchList = `${API}/add_watch_list?api_token=${data.api_token}&user_id=${data.user_id}`;
  var formData = new FormData();
  formData.append("courses_id", data.courses_id);
  formData.append("section_id", data.section_id);

  return axios
    .post(addWatchList, formData)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const getwatchlist = (data) => {
  let getWatchList = `${API}/watch_list?api_token=${data.api_token}&user_id=${data.user_id}`;
  return axios
    .get(getWatchList)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const getfollowunfollowlist = (data) => {
  console.log("CHECK DATA FOR URL VERIFICATION", data);
  let fetchList = `${API}/${data.url}?api_token=${data.api_token}&user_id=${data.user_id}&ids=${data.ids}`;
  console.log("Check url to fetch list", fetchList);
  return axios
    .get(fetchList)
    .then((res) => {
      console.log("Check follow unfollow list -->>", res.data);
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const removefolloweruser = (data) => {
  console.log("Check Remove User Data", data);
  var formData = new FormData();
  formData.append("follower_userid", data.follower_userid);

  let removeUser = `${API}/unfollow_follower?api_token=${data.api_token}&user_id=${data.user_id}&ids=${data.ids}`;
  return axios
    .post(removeUser, formData)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const upcominglearning = (data) => {
  console.log("Check upcoming learning body", data);
  let getupcominglearning = `${API}/upcoming_learning?api_token=${data.api_token}&user_id=${data.user_id}&categoryName=${data.categoryName}`;
  return axios
    .get(getupcominglearning)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
    })
    .catch((err) => {
      throw err;
    });
};
