import React from "react";
import { Link } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import "./WatchHistory.scss";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import { DisplayRatings } from "../Ratings/Ratings";
import { AuthorName } from "../../StyleConfig/Common/CommonStyles";
import MediaQuery from "react-responsive";

function WatchHistory({ watchHistory, category }) {
  console.log("Check Watch Histroy", watchHistory.length);
  const StartLayout = () => {
    return <StarIcon style={{ color: "#325288" }} />;
  };

  const startRating = (rating) => {
    for (let i = 1; i == rating; i++) {
      StartLayout();
    }
  };

  return (
    <div>
      <div style={{ height: "20px" }} />
      {category == "WatchHistory" && (
        <>
          <MediaQuery minWidth={768}>
            <div className="history__details__container">
              <div className="row">
                <div className="col-md-4">
                  <span className="history__details__title">Course name</span>
                </div>
                <div className="col-md-3">
                  <span className="history__details__title">Hours</span>
                </div>
                <div className="col-md-2">
                  <span className="history__details__title">Watched</span>
                </div>
                <div className="col-md-3 d-flex justify-center">
                  <span className="history__details__title">Rating</span>
                </div>
              </div>
            </div>
          </MediaQuery>

          {watchHistory.map((watched, index) => {
            return (
              <div className="history__courses">
                <Link
                  to={{
                    pathname: `/courses/mycourses/coursedetail/${parseInt(
                      watched.id
                    )}`,
                    state: watched,
                  }}
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-flex">
                        <img
                          src={
                            IMAGECONFIG.IMAGECONFIG.MEDIUM + watched.media_id
                          }
                          alt={watched.name}
                          className="history__image"
                        />
                        <div className="ms-4 flex-column justify-center">
                          <h6 className="mb-2">{watched.name}</h6>
                          <AuthorName style={{ fontSize: "14px" }}>
                            - {watched.author_name}
                          </AuthorName>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 flex-column justify-center">
                      <span>{watched.duration}</span>
                    </div>
                    <div className="col-md-2 flex-column justify-center">
                      <span>
                        {watched.view_section_ctn}/{watched.total_sections_ctn}
                      </span>
                    </div>
                    <div className="col-md-3 d-flex">
                      <div className="flex-column ratings__container">
                        <DisplayRatings
                          rating={watched.course_rate}
                          className="ratings__container"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default WatchHistory;
