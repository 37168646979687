/* eslint-disable import/no-anonymous-default-export */
export default {
  IMAGECONFIG: {
    SMALL: "https://api.skillohana.com/api/v1/media/small/",
    THUMBNAIL: "https://api.skillohana.com/api/v1/media/thumb/",
    MEDIUM: "https://api.skillohana.com/api/v1/media/medium/",
    PROFILE: "https://api.skillohana.com/api/v1/media/profile/",
    ORIGINAL: "https://api.skillohana.com/api/v1/media/original/",
    BANNER: "https://api.skillohana.com/api/v1/media/banner/",
  },
};
