import React, { useState, useEffect } from "react";
import { useHistory, Link, useParams, useLocation } from "react-router-dom";
import AppHeader from "../../components/AppHeader/AppHeader";
import Profile from "../../assets/profile/profilepic.png";
import "./MyProfile.scss";
import SocialMediaIcons from "../../StyleConfig/SocialMediaIcons";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  ProfilePageContainer,
  ProfileImageContainer,
  ProfileImage,
  AppFooter,
} from "../../StyleConfig/Common/CommonStyles";
import IMAGECONFIG from "../../StyleConfig/MediaImage";
import { ShimmerEffect } from "../../StyleConfig/Common/Shimmer";
import {
  followunfollow,
  getfollowunfollowlist,
  getloginuserprofile,
  getmycourses,
} from "../../Core/coreapicalls";
import { useDataLayerValue } from "../../context/DataLayer";
import Footer from "../../components/Footer/Footer";
import AppModal from "../../components/AppModal/AppModal";
import FollowUnfollowList from "../../components/FollowUnfollowList/FollowUnfollowList";

function MyProfile(props) {
  let { id } = useParams();
  let location = useLocation();
  const loginuserid = JSON.parse(localStorage.getItem("userId"));
  const [{ loginuserdetails }, dispatch] = useDataLayerValue();
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [peopleList, setPeopleList] = useState([]);
  const [role, setRole] = useState("");

  const storeUserValue = async (userDetails) => {
    console.log("Check user store value ---->>> ####", userDetails);
    const userProfileData = userDetails;
    const storedValue = await localStorage.getItem("userprofiledata");
    const prevStoredValue = await JSON.parse(storedValue);
    if (prevStoredValue) {
      await localStorage.setItem(
        "userprofiledata",
        JSON.stringify(userProfileData)
      );
      // console.log("Value Present");
    } else {
      await localStorage.setItem(
        "userprofiledata",
        JSON.stringify(userProfileData)
      );
    }
  };

  /**
   * Get User Profile API
   */
  const getprofileapi = () => {
    console.log("Get Profile Api Called.");
    setLoading(true);
    let reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: loginuserid,
      id: parseInt(id),
    };
    getloginuserprofile(reqBody)
      .then((data) => {
        console.log("Check Profile data --->>>", data.data[0]);
        if (data.status === true) {
          storeUserValue(data.data[0]);
          dispatch({
            loginuserdetails: data.data[0],
            type: "SET__LOGINUSER__PROFILE",
          });
        }
        setLoading(false);
        if (data.status === false) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  };

  /**
   * Get Login User Course API
   */

  const getcourse = () => {
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: location.pathname === "/profile" ? loginuserid : id,
    };
    getmycourses(reqBody)
      .then((data) => {
        setLoading(false);
        if (data.status === true) {
          // setMycourses(data.data);
          dispatch({
            mycourses: data.data,
            type: "SET__MYCOURSES",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Follow / UnFollow API
   */

  const followunfollowapi = (status) => {
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: loginuserid,
      follower_userid: id,
      status: status,
    };
    followunfollow(reqBody)
      .then((data) => {
        // console.log("Check final res in my profile", data);
        if (data.status === true) {
          getprofileapi();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = (name) => {
    setPeopleList([]);
    if (name == "followUnfollow") {
      setModalState(false);
    } else {
      return null;
    }
  };

  const fetchfollowunfollowlist = (url) => {
    setListLoading(true);
    let reqBody = {
      api_token: JSON.parse(localStorage.getItem("token")),
      user_id: JSON.parse(localStorage.getItem("userId")),
      ids: id ? id : "",
      url: url,
    };
    getfollowunfollowlist(reqBody)
      .then((res) => {
        console.log("Check FOLLOW //// UNFOLLOW list", res.data);
        if (res.status === true) {
          setPeopleList(res.data);
          setListLoading(false);
        }
      })
      .catch((err) => {
        setListLoading(false);

        console.log("Check Follow Unfollow error", err);
      });
  };

  useEffect(() => {
    console.log(
      "Check user profile data from localstorage previous",
      JSON.parse(localStorage.getItem("userprofiledata"))
    );
    getprofileapi();
  }, [location]);

  return (
    <ProfilePageContainer>
      <AppModal
        openModal={modalState}
        data={modalState}
        name="followUnfollow"
        handleClose={handleClose}
      >
        {peopleList && (
          <FollowUnfollowList
            data={peopleList}
            role={role}
            handleClose={handleClose}
            name="followUnfollow"
            fetchFollowing={fetchfollowunfollowlist}
            getProfile={getprofileapi}
            listloading={listLoading}
          />
        )}
      </AppModal>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <ProfileImageContainer>
              {loading && (
                <ShimmerEffect
                  width="180px"
                  height="180px"
                  borderRadius="100%"
                />
              )}
              {loading === false && (
                <ProfileImage
                  src={`${IMAGECONFIG.IMAGECONFIG.PROFILE}${loginuserdetails.media_id}`}
                  alt="profile pic"
                />
              )}
            </ProfileImageContainer>
            <div className="profile__detail__container">
              <h4 className="profile__name">{loginuserdetails.name}</h4>
              <span className="profile__desc">
                {loginuserdetails.profession}
              </span>
              <div className="mt-4 mb-2 d-flex">
                <LocationOnIcon />
                <h6 className="ms-2 mt-1 profile__designation">
                  {loginuserdetails.location}
                </h6>
              </div>

              <div style={{ height: "20px" }} />
              <div className="flex-row justify-between width250__px">
                <div
                  className="followers__container"
                  onClick={() => {
                    setRole("Followers");
                    fetchfollowunfollowlist("fetch_follower");
                    setModalState(true);
                  }}
                >
                  <span className="followers__number">
                    {loginuserdetails.follower_ctn}
                  </span>{" "}
                  <br />
                  <span className="followers">Followers</span>
                </div>
                <div
                  className="followers__container"
                  onClick={() => {
                    setRole("Following");
                    fetchfollowunfollowlist("fetch_following");
                    setModalState(true);
                  }}
                >
                  <span className="following__number">
                    {loginuserdetails.following_ctn}
                  </span>{" "}
                  <br />
                  <span className="following">Following</span>
                </div>
              </div>
              <div style={{ height: "20px" }} />

              <div className="flex-row justify-between">
                <img
                  src={SocialMediaIcons.FACEBOOK}
                  alt="facebook"
                  className="sm__icons"
                />
                <img
                  src={SocialMediaIcons.INSTAGRAM}
                  alt="instagram"
                  className="sm__icons"
                />
                <img
                  src={SocialMediaIcons.LINKEDIN}
                  alt="linkedin"
                  className="sm__icons"
                />
                <img
                  src={SocialMediaIcons.YOUTUBE}
                  alt="youtube"
                  className="sm__icons"
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div style={{ height: "20px" }} />

            {location.pathname === "/myprofile" && (
              <div className="flex-end">
                <Link
                  to={{
                    pathname: "/myprofile/edit",
                    state: {
                      profile: loginuserdetails,
                    },
                  }}
                >
                  <button className="btn btn-primary edit__profile">
                    Edit Profile
                  </button>
                </Link>
                <Link
                  onClick={() => {
                    localStorage.clear();
                  }}
                  to={{
                    pathname: "/",
                  }}
                >
                  <button className="btn btn-primary log__out">Logout</button>
                </Link>
              </div>
            )}

            <div className="aboutme__section">
              <h4 className="about__me">About Me</h4>
              <span>{loginuserdetails.about_me}</span>
            </div>
            <div style={{ height: "10px" }} />
            <div className="aboutme__section">
              <h4 className="about__me">Achievement</h4>
              <span>{loginuserdetails.achievement}</span>
            </div>
            <div style={{ height: "20px" }} />
            {id != JSON.parse(localStorage.getItem("userId")) ? (
              location.pathname !== "/myprofile" ? (
                <div className="flex-row justify-center">
                  <button
                    className="btn btn-primary log__out"
                    onClick={() => {
                      if (loginuserdetails.follower_status === 0) {
                        followunfollowapi("follow");
                      } else {
                        followunfollowapi("unfollow");
                      }
                    }}
                  >
                    {loginuserdetails.follower_status === 0 ? (
                      <span>Follow</span>
                    ) : (
                      <span>Unfollow</span>
                    )}
                  </button>
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}

            <div style={{ height: "20px" }} />
          </div>
        </div>
      </div>
      <Footer />
    </ProfilePageContainer>
  );
}

export default MyProfile;
