import React, { useState } from "react";

function Achievements({ data, handleChange }) {
  const [userachievements, setUserachievements] = useState("");

  return (
    <div className="row justify-center">
      <div className="col-md-8">
        <h5 className="form__heading">Achievements</h5>

        <div className="form-group">
          <label className="text-light">Achievements</label>
          <textarea
            style={{ height: "200px" }}
            className="form-control"
            placeholder="Achievements"
            onChange={handleChange("achievements")}
            type="text"
            value={data.achievements}
          />
        </div>
      </div>
    </div>
  );
}

export default Achievements;
