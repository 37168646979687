/* eslint-disable default-case */
export const initialState = {
  loginname: "",
  token: "",
  userid: "",
  mediaid: 0,
  /**
   * USER ID FOR PROFILE
   */
  profileuserid: null,
  /**
   * USER PROFILE PIC
   */
  profilepic: [],
  /**
   * LOGIN USER PROFILE
   */
  loginuserdetails: {},
  /*
  MY COURSES
  */

  mycourses: [],
  /*
  EXPLORE COURSES
  */
  explorecourses: [],
  /*
  COURSES TAB VALUE (MY COURSES)
  */
  coursesTabValue: 1,
  segmentTabValue: 1,
  segmentTabValueForCourse: 1,
  coursesSectionValue: "",
  /**
   MODAL STATE
   */
  openModal: false,
  /**
   * ASK ABOUT SECTION
   */

  name: "",
  aboutyourself: "",
  areaofinterest: [],
  achievements: "",

  /**
   * Video Player
   */
  videopath: "",

  /**
   * GET LECTURE
   */

  getlecture: [],
  addeditlecture: "addlecture",
};

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "SET__LOGIN__USER":
      return {
        ...state,
        userLogin: action.userLogin,
      };
    case "SET__MEDIA__ID":
      return {
        ...state,
        mediaid: action.mediaid,
      };
    case "SET__USER__PROFILEPIC":
      return {
        ...state,
        profilepic: action.profilepic,
      };
    case "SET__PROFILEUSER__ID":
      return {
        ...state,
        profileuserid: action.profileuserid,
      };
    case "SET__COURSESTAB__VALUE":
      return {
        ...state,
        coursesTabValue: action.coursesTabValue,
      };
    case "SET__SEGMENTTAB__VALUE":
      return {
        ...state,
        segmentTabValue: action.segmentTabValue,
      };
    case "SET__SEGMENTTABFORCOURSE__VALUE":
      return {
        ...state,
        segmentTabValueForCourse: action.segmentTabValueForCourse,
      };
    case "SET__MODAL__VALUE":
      return {
        ...state,
        openModal: action.openModal,
      };
    case "SET__ABOUT__YOURSELF":
      return {
        ...state,
        name: action.name,
        aboutyourself: action.aboutyourself,
      };
    case "SET__AREAOF__INTEREST":
      return {
        ...state,
        areaofinterest: action.areaofinterest,
      };
    case "SET__LOGINUSER__PROFILE":
      return {
        ...state,
        loginuserdetails: action.loginuserdetails,
      };
    case "SET__MYCOURSES":
      return {
        ...state,
        mycourses: action.mycourses,
      };
    case "SET__EXPLORE__COURSES":
      return {
        ...state,
        explorecourses: action.explorecourses,
      };
    case "SET__VIDEO__PATH":
      return {
        ...state,
        videopath: action.videopath,
      };
    case "SET__GET__LECTURE":
      return {
        ...state,
        getlecture: action.getlecture,
      };
    case "SET__ADDEDIT__LECTURE":
      return {
        ...state,
        addeditlecture: action.addeditlecture,
      };
    default:
      return state;
  }
};

export default reducer;
