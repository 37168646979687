import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import Chips, { RemovableChips } from "../../../components/Chips/Chips";
import { gettingtags } from "../../../Core/coreapicalls";
import { useDataLayerValue, InterestContext } from "../../../context/DataLayer";
import Theme from "../../../StyleConfig/Theme";

function AreaofInterest() {
  let { id } = useParams();
  const [{}, dispatch] = useDataLayerValue();
  const [chipData, setChipData] = React.useState([]);
  const [interest, setInterest] = useContext(InterestContext);

  /**
   * Get Categories Api (Tags)
   */

  const getTags = () => {
    const reqbody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    gettingtags(reqbody)
      .then((data) => {
        if (data.status === true) {
          console.log("Check Chip Data", data);
          setChipData(data.data);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    console.log("Check Interest length ==>>", interest.length);
    getTags();
  }, [interest]);

  return (
    <div className="row justify-center">
      <div className="col-md-8">
        {chipData.length == 0 ? (
          <div
            className="d-flex justify-center align-center"
            style={{ height: "400px" }}
          >
            <div className="flex-column justify-center">
              <FadeLoader
                color={Theme.LIGHT.INNERLOADER}
                loading={true}
                size={50}
              />
            </div>
          </div>
        ) : (
          <>
            <h5>Please Select Interest</h5>
            <div style={{ height: "10px" }} />
            <Chips chipData={chipData} />
            <div style={{ height: "10px" }} />
            {/* <h5>Please Select Interest</h5> */}

            <div className="form-group">
              <div className="form-control" style={{ height: "200px" }}>
                {interest.length !== 0 && id !== "edit" ? (
                  <RemovableChips data={[...new Set(interest)]} />
                ) : (
                  id !== "edit" && (
                    <div>
                      {/* <h6 className="text-center">Please Select Interest</h6> */}
                    </div>
                  )
                )}
                {id === "edit" ? (
                  <RemovableChips data={[...new Set(interest)]} />
                ) : (
                  id === "edit" && (
                    <div>
                      {/* <h6 className="text-center">Please Select Interest</h6> */}
                    </div>
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AreaofInterest;
