import React from "react";
import { Link } from "react-router-dom";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import moment from "moment";
import "./HomePageReview.scss";
import {
  Cards,
  CourseSectionTitle,
} from "../../../StyleConfig/Common/CommonStyles";
import IMAGECONFIG from "../../../StyleConfig/MediaImage";
import { useDataLayerValue } from "../../../context/DataLayer";

function HomePageReviews({ userReviews, reviewTitle }) {
  const [{}, dispatch] = useDataLayerValue();
  return (
    <div>
      <CourseSectionTitle className="margin__left__30px">
        {reviewTitle}
      </CourseSectionTitle>
      <div className="review__container">
        {userReviews.map((reviews, index) => {
          // console.log("Check reviews", reviews.author_mediaid);
          return (
            <Cards theme={"Light"} key={index + "reviews"}>
              <div className="flex-row">
                <div className="image__container">
                  <img
                    className="reviewer__profileImg"
                    src={`${IMAGECONFIG.IMAGECONFIG.THUMBNAIL}${reviews.author_mediaid}`}
                    alt="profile"
                  />
                </div>
                <div className="reviewerdetails__container">
                  <Link
                    to={{
                      pathname: `/profile/${parseInt(reviews.author_userid)}`,
                    }}
                  >
                    <span className="reviewer__name">
                      {reviews.author_name}
                    </span>
                  </Link>
                  <div className="flex-row">
                    <TimelapseIcon
                      style={{
                        color: "#FD7F44",
                        margin: "5px",
                        width: "15px",
                        height: "15px",
                      }}
                    />
                    <span className="reviewerpost__duration">
                      {moment(reviews.created_at).fromNow()}
                    </span>
                  </div>
                </div>
              </div>
              <div style={{ height: "10px" }} />
              <Link
                to={{
                  pathname: `/courses/mycourses/coursedetail/${parseInt(
                    reviews.id
                  )}`,
                }}
                onClick={() => {
                  dispatch({
                    segmentTabValue: 2,
                    type: "SET__SEGMENTTAB__VALUE",
                  });
                }}
              >
                <div className="authors__news">{reviews.info}</div>
              </Link>
              <div style={{ height: "10px" }} />
            </Cards>
          );
        })}
      </div>
    </div>
  );
}

export default HomePageReviews;
