import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import verify from "../../assets/VerifyEmail/verifyback.png";
import { resendemail } from "../../auth/helper";
import AppSnackbar from "../../components/AppSnackbar/AppSnackbar";

function VerifyEmail() {
  const [email, setEmail] = useState(JSON.parse(localStorage.getItem("email")));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const resendEmail = () => {
    setSuccess(false);
    setError(false);
    setLoading(true);
    resendemail(email)
      .then((res) => {
        setResponseMessage("Email send successfully.");
        setLoading(false);
        setError(false);

        setSuccess(true);

        console.log("Check resend email response in main", res);
      })
      .catch((err) => {
        setResponseMessage("Something went wrong.");
        setLoading(false);
        setSuccess(false);
        setError(true);

        console.log("Resend response in error", err);
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div style={{ display: "grid", placeItems: "center" }}>
            <div>
              <img
                src={verify}
                alt="email-verify"
                style={{ height: "auto", width: "auto", objectFit: "contain" }}
              />
            </div>
            <div>
              <h5>Verify your email address</h5>
            </div>

            <div className="mt-5 mb-2">
              <h6>
                You've entered <span style={{ color: "#1b98f5" }}>{email}</span>{" "}
                as the email address.
              </h6>
            </div>
            <div className="mt-2 mb-2">
              <h6>
                Please login to your email address and verify your account.
              </h6>
            </div>

            <div className="mt-5 mb-5">
              {loading ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={() => {
                    resendEmail();
                  }}
                >
                  <strong>Resend email</strong>
                </button>
              )}
            </div>
            <Link
              to={{
                pathname: "/signin",
              }}
            >
              <div className="mt-2">
                <h5 style={{ color: "#1b98f5" }}>Continue to sign in.</h5>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {success && <AppSnackbar message={responseMessage} severity="success" />}
      {error && <AppSnackbar message={responseMessage} severity="error" />}
    </div>
  );
}

export default VerifyEmail;
