/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DragDrop from "../../../components/DragDrop/DragDrop";
import { v4 as uuidv4 } from "uuid";
import AppModal from "../../../components/AppModal/AppModal";
import { useDataLayerValue } from "../../../context/DataLayer";
import Footer from "../../../components/Footer/Footer";
import AddLectures from "./AddLectures";
import "./CreateCourse.scss";
import {
  createcourse,
  editcourse,
  getcoursedetails,
  gettingtags,
  getuploadlecturestocreatecourse,
  ondeletelecture,
} from "../../../Core/coreapicalls";
import {
  ChipsCard,
  LectureCards,
  LectureTitle,
  SaveButton,
  UploadVideoPercentage,
  VideoPercentageContainer,
  SaveButtonText,
  BackButton,
  CancelButton,
} from "../../../StyleConfig/Common/CommonStyles";
import Theme from "../../../StyleConfig/Theme";
import { CourseCategoriesChips } from "../../../components/Chips/Chips";
import Loader from "../../../components/Loader/Loader";
import AppSnackbar from "../../../components/AppSnackbar/AppSnackbar";

function CreateCourse({ setLoading }) {
  let history = useHistory();
  let { id } = useParams();
  const [{ getlecture }, dispatch] = useDataLayerValue();
  const [modalState, setModalState] = useState({
    addlecturemodal: false,
    conformlecturedeletemodal: false,
  });
  const [courseUniqueId, setCourseUniqueId] = useState("");
  const [coursePic, setCoursePic] = useState([]);
  const [coursePicName, setCoursePicName] = useState([]);
  const [values, setValues] = useState({
    courseid: 0,
    coursetitle: "",
    coursedesc: "",
    courseduration: "",
    coursecategory: "",
    prev_img: "",
    media_id: 0,
    loader: false,
    success: false,
    error: false,
  });
  // const [getLectures, setGetlectures] = useState([]);
  const [usersInterest, setUsersInterest] = useState([]);
  const [editLectureData, setEditLectureData] = useState("");
  const [sectionId, setSectionId] = useState(0);
  const [responseMessage, setResponseMessage] = useState("");
  const {
    courseid,
    prev_img,
    media_id,
    coursetitle,
    coursedesc,
    courseduration,
    coursecategory,
    success,
    error,
    loader,
  } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleCourseCategory = (selectedcategory) => {
    console.log("Check Selected Category", selectedcategory);
    setValues({ ...values, coursecategory: selectedcategory.name });
  };
  /**
   *
   * @param {*} acceptedFiles Profile Pic Data
   */
  const handleDrop = (acceptedFiles) => {
    setCoursePic(acceptedFiles[0]);
    setCoursePicName(acceptedFiles.map((file) => file.name));
  };

  const handleClose = (name) => {
    if (name == "addlecture") {
      setModalState({ ...modalState, addlecturemodal: false });
    } else {
      setModalState({ ...modalState, conformlecturedeletemodal: false });
    }
  };

  /**
   * Get Submitted Lectures API
   */
  const getlecturesapi = () => {
    dispatch({
      getlecture: [],
      type: "SET__GET__LECTURE",
    });
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
      temp_code: courseUniqueId,
      courses_id: id ? parseInt(id) : "",
    };
    getuploadlecturestocreatecourse(reqBody)
      .then((res) => {
        console.log("Check get lectures", res);
        if (res.status === true) {
          // setGetlectures(res.data);
          dispatch({
            getlecture: res.data,
            type: "SET__GET__LECTURE",
          });
        }
      })
      .catch((err) => console.log((err) => console.log("Check err", err)));
  };

  /**
   * Get User Interests
   */

  const getuserinterestapi = () => {
    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    gettingtags(reqBody)
      .then((res) => {
        console.log("Check get interests", res);
        if (res.status === true) {
          setUsersInterest(res.data);
        }
      })
      .catch((err) => console.log((err) => console.log("Check err", err)));
  };

  const getcoursedetailapi = () => {
    let reqBody = {
      id: id,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
    };
    getcoursedetails(reqBody)
      .then((res) => {
        console.log("Get Course detail in edit course", res.data);
        if (res.status === true) {
          setValues({
            ...values,
            courseid: res.data.id,
            coursetitle: res.data.name,
            coursedesc: res.data.info,
            courseduration: res.data.duration,
            coursecategory: res.data.cat_id,
            prev_img: res.data.img,
            media_id: res.data.media_id,
          });
          // setCoursePic(res.data.img_src);
          // setGetlectures(res.sections);
          dispatch({
            getlecture: res.sections.reverse(),
            type: "SET__GET__LECTURE",
          });
        }
        if (res.status === false) {
          //
        }
      })
      .catch((error) => {});
  };

  const getdeletelectureapi = () => {
    setValues({ ...values, loader: true });
    let reqBody = {
      sections_id: sectionId,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
    };
    ondeletelecture(reqBody)
      .then((res) => {
        console.log("Delete Lecture response in main file", res);
        setValues({ ...values, loader: false });
        if (res.status === true) {
          setResponseMessage("Lecture deleted successfully!");
          setValues({ ...values, error: false, success: true });
          getlecturesapi();
          handleClose("deletelecture");
        }
      })
      .catch((error) => {
        setValues({ ...values, loader: false, success: false, error: true });
        setResponseMessage("Something went wrong!");
        console.log("Delete Lecture error in main file", error);
        handleClose("deletelecture");
      });
  };

  const oneditcourse = (e) => {
    console.log("Edit Course submit");
    setLoading(true);
    const reqBody = {
      name: coursetitle,
      info: coursedesc,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
      cat_id: coursecategory,
      duration: courseduration,
      img: coursePic,
      prev_img: prev_img,
      courses_id: courseid,
      media_id: media_id,
    };
    editcourse(reqBody)
      .then((res) => {
        setLoading(false);
        console.log("Check response for course edited", res);
        if (res.status === true) {
          history.push("/courses/mycourses");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onsubmit = (e) => {
    console.log("New Course submit");
    setLoading(true);
    setValues({ ...values, error: false, success: false });
    const reqBody = {
      name: coursetitle,
      info: coursedesc,
      user_id: JSON.parse(localStorage.getItem("userId")),
      api_token: JSON.parse(localStorage.getItem("token")),
      cat_id: coursecategory,
      duration: courseduration,
      img: coursePic,
      temp_code: courseUniqueId,
    };
    createcourse(reqBody)
      .then((res) => {
        setLoading(false);

        console.log("Check response", res);
        if (res.status === true) {
          setResponseMessage("Course added successfully.");
          setValues({ ...values, error: false, success: true });
          setTimeout(() => {
            history.push("/courses/mycourses");
          }, 1000);
        }
      })
      .catch((err) => {
        setLoading(false);
        setResponseMessage("Something went wrong please try again.");
        setValues({ ...values, success: false, error: true });
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("Check Create Course Id", id);
    if (id) {
      getcoursedetailapi();
    } else {
      dispatch({
        getlecture: [],
        type: "SET__GET__LECTURE",
      });
    }
    setCourseUniqueId(uuidv4());
    getuserinterestapi();
  }, []);

  return (
    <div className="overflow-auto" style={{ marginTop: "70px" }}>
      <AppModal
        openModal={modalState.addlecturemodal}
        data={modalState}
        name="addlecture"
        handleClose={handleClose}
      >
        <AddLectures
          uniqueid={courseUniqueId}
          getLectures={getlecturesapi}
          handleClose={handleClose}
          courseData={values}
          editLecture={editLectureData}
        />
      </AppModal>
      <AppModal
        openModal={modalState.conformlecturedeletemodal}
        data={modalState}
        name="deletelecture"
        handleClose={handleClose}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>Are you sure you want to delete this lecture ?</h5>
            </div>
          </div>
          <div style={{ height: "50px" }} />
          <div className="d-flex around">
            <CancelButton
              className="btn"
              onClick={() => handleClose("deletelecture")}
            >
              <SaveButtonText>Cancel</SaveButtonText>
            </CancelButton>
            <SaveButton className="btn" onClick={() => getdeletelectureapi()}>
              {loader ? (
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                <SaveButtonText>Confirm</SaveButtonText>
              )}
            </SaveButton>
          </div>
        </div>

        {/* <button onClick={() => handleClose("deletelecture")}>delete</button> */}
      </AppModal>
      <div className="container">
        <div className="row justify-center">
          <div className="col-md-8">
            <div style={{ height: "15px" }} />
            <h6 className="form__heading">Course Title</h6>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="Course Title"
                onChange={handleChange("coursetitle")}
                type="text"
                value={coursetitle}
              />
            </div>
            <div style={{ height: "15px" }} />
            <h6 className="form__heading">Course Description</h6>
            <div className="form-group">
              <textarea
                style={{ height: "100px" }}
                className="form-control"
                placeholder="Course Description"
                onChange={handleChange("coursedesc")}
                type="text"
                value={coursedesc}
              />
            </div>
            <div style={{ height: "15px" }} />

            <h6 className="form__heading">Course Duration</h6>
            <div className="form-group">
              <input
                className="form-control"
                placeholder="eg: 01.00 hr"
                onChange={handleChange("courseduration")}
                type="text"
                value={courseduration}
              />
            </div>
            <div style={{ height: "15px" }} />
            <h6 className="form__heading">Course Category</h6>
            <CourseCategoriesChips
              chipData={usersInterest}
              handleCourseCategory={handleCourseCategory}
            />
            <div style={{ height: "10px" }} />
            <div>
              {coursecategory && <ChipsCard>{coursecategory}</ChipsCard>}
            </div>
            <div style={{ height: "15px" }} />
            <h6 className="form__heading">Course Image</h6>
            <div className="form-group">
              <div className="form-control">
                <DragDrop
                  styles={"createcourse__dragdrop__container"}
                  handleDrop={handleDrop}
                  data={values}
                  image={coursePic}
                  imagename={coursePicName}
                />
              </div>
            </div>
            <div style={{ height: "15px" }} />
            <div className="flex-row space">
              <h6 className="form__heading">Add Course Lectures</h6>
              <div
                onClick={() => {
                  dispatch({
                    addeditlecture: "addlecture",
                    type: "SET__ADDEDIT__LECTURE",
                  });
                  setModalState({ ...modalState, addlecturemodal: true });
                }}
              >
                <AddCircleIcon
                  style={{ color: "#1EA69A", margin: "2px", cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="form-group">
              {getlecture.length > 0 && (
                <div
                  className="form-control"
                  style={{ height: "350px", overflow: "auto" }}
                >
                  {getlecture &&
                    getlecture.map((lecture, index) => {
                      let newIndex = index + 1;
                      return (
                        <div key={index + "lecture"}>
                          <div
                            className="d-flex flex-end"
                            onClick={() => {
                              setSectionId(lecture.id);
                              setModalState({
                                ...modalState,
                                conformlecturedeletemodal: true,
                              });
                            }}
                          >
                            <CancelIcon style={{ cursor: "pointer" }} />
                          </div>{" "}
                          <LectureCards
                            style={{
                              marginTop: "-10px",
                            }}
                            background={
                              newIndex % 2 == 0
                                ? `${Theme.LIGHT.LECTURECARDBACKGROUNDONE}`
                                : `${Theme.LIGHT.LECTURECARDBACKGROUNTWO}`
                            }
                          >
                            <div className="flex-row center">
                              <VideoPercentageContainer
                                background={
                                  newIndex % 2 == 0
                                    ? `${Theme.LIGHT.LECTUREPERCENTAGEONE}`
                                    : `${Theme.LIGHT.LECTUREPERCENTAGETWO}`
                                }
                              >
                                <UploadVideoPercentage>
                                  {newIndex}
                                </UploadVideoPercentage>
                              </VideoPercentageContainer>
                              <div
                                className="flex-column flex-grow-1 center"
                                style={{ marginLeft: "10px" }}
                              >
                                <LectureTitle>{lecture.title}</LectureTitle>
                                <div className="flex-row">
                                  <AccessTimeIcon
                                    style={{
                                      color: "#A6A6A6",
                                      marginLeft: "10px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      color: "#A6A6A6",
                                      fontWeight: "500",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {lecture.duration}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="flex-column justify-center"
                                onClick={() => {
                                  setEditLectureData(lecture);
                                  dispatch({
                                    addeditlecture: "editlecture",
                                    type: "SET__ADDEDIT__LECTURE",
                                  });
                                  setModalState({
                                    ...modalState,
                                    addlecturemodal: true,
                                  });
                                }}
                              >
                                <h6 style={{ cursor: "pointer" }}>Edit</h6>
                              </div>
                            </div>
                          </LectureCards>
                        </div>
                      );
                    })}
                </div>
              )}
              <div style={{ height: "15px" }} />
              <div className="flex-row justify-center">
                <button
                  className="btn back__button"
                  onClick={() => history.push("/courses/mycourses")}
                >
                  <h5 className="back__text">Cancel</h5>
                </button>
                <button
                  className="btn next__button"
                  onClick={() => {
                    if (id) {
                      oneditcourse();
                    } else {
                      onsubmit();
                    }
                  }}
                >
                  <h5 className="next__text">Add</h5>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "10px" }} />
      <Footer />
      {success && <AppSnackbar message={responseMessage} severity="success" />}
      {error && <AppSnackbar message={responseMessage} severity="error" />}
    </div>
  );
}

export default Loader(CreateCourse);
