import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CoursesCard from "../../components/CoursesCard/CoursesCard";
import Options from "../../components/Options";
import Search from "../../components/Search/Search";
import WatchHistory from "../../components/WatchHistory/WatchHistory";
import { getexplorecourses, getkeywordsearch } from "../../Core/coreapicalls";
import Footer from "../../components/Footer/Footer";
import { FadeLoader } from "react-spinners";
import Theme from "../../StyleConfig/Theme";
import { LoaderContainer } from "../../StyleConfig/Common/CommonStyles";

const sections = [
  {
    id: 1,
    name: "Explore",
    path: "/courses/explore",
  },
  {
    id: 2,
    name: "My Courses",
    path: "/courses/mycourses",
  },
  {
    id: 3,
    name: "Watch History",
    path: "/courses/watchhistory",
  },
];

function ExploreCourses() {
  let { id } = useParams();
  const [exploreCourse, setExploreCourse] = useState([]);
  const [loading, setLoading] = useState(true);

  const getexplorecourse = () => {
    console.log("Check Cat_ID", id);
    setLoading(true);
    let reqBody = {
      cat_id: id ? id : "",
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getexplorecourses(reqBody)
      .then((data) => {
        setLoading(false);
        console.log("Check Explore course response", data.data);
        if (data.status === true) {
          setExploreCourse(data.data);
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };

  const getkeywordsearchapi = (keyword, cancelToken) => {
    let reqBody = {
      keyword: keyword ? keyword : "",
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getkeywordsearch(reqBody, cancelToken)
      .then((data) => {
        console.log("Check keyword search response", data);
        if (data.status === true) {
          setExploreCourse(data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getexplorecourse();
  }, [id]);

  return (
    <div className="my_courses_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <Options value={sections} section={"MyCourse"} />
            </div>
            {loading === false && (
              <div>
                <Search searchBy={getkeywordsearchapi} />
                <CoursesCard
                  getcoursebycategory={getexplorecourse}
                  courses={exploreCourse}
                  category={"Explorecourses"}
                  routePath="explore"
                  checkLoading={loading}
                />
              </div>
            )}
            {loading && (
              <LoaderContainer>
                <FadeLoader
                  color={Theme.LIGHT.INNERLOADER}
                  loading={true}
                  size={50}
                />
              </LoaderContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ExploreCourses;
