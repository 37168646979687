import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./index";

const LoggedinRoute = ({ component: Component, ...rest }) => {
  console.log("Check Component", Component);
  return (
    <Route
      {...rest}
      render={(props) => {
        console.log("Route props", props.location);

        return !isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default LoggedinRoute;
