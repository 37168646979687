import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, HashRouter } from "react-router-dom";
import AskAbout from "./pages/AskAbout/AskAbout";
import HomePage from "./pages/HomePage/HomePage";
import LandingPage from "./pages/LandingPage/LandingPage";
import CourseDetail from "./pages/MyCourses/CourseDetails/CourseDetail";
import CreateCourse from "./pages/MyCourses/CreateCourse/CreateCourse";
import MyCourses from "./pages/MyCourses/MyCourses";
import EditProfile from "./pages/MyProfile/EditProfile/EditProfile";
import MyProfile from "./pages/MyProfile/MyProfile";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import PrivateRoute from "./auth/helper/PrivateRoutes";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import LoggedinRoute from "./auth/helper/LoggedinRoute";
import AppHeader from "./components/AppHeader/AppHeader";
import ExploreCourses from "./pages/ExploreCourses/ExploreCourses";
import WatchCourseHistory from "./pages/WatchCourseHistory/WatchCourseHistory";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import Recommend from "./pages/HomePage/Recommend";
import Recent from "./pages/HomePage/Recent";
import Publish from "./pages/HomePage/Publish";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <LoggedinRoute path="/verifyemail" exact component={VerifyEmail} />
        <LoggedinRoute path="/" exact component={LandingPage} />
        <LoggedinRoute path="/signup" exact component={SignUp} />
        <LoggedinRoute path="/signin" exact component={SignIn} />
        <LoggedinRoute
          path="/forgetpassword"
          exact
          component={ForgetPassword}
        />
        <PrivateRoutes />
      </Switch>
    </HashRouter>
  );
};

export default Routes;

const PrivateRoutes = () => {
  return (
    <>
      <AppHeader />
      <Switch>
        <PrivateRoute path="/home" exact component={HomePage} />
        <PrivateRoute path="/askabout" exact component={AskAbout} />
        <PrivateRoute path="/askabout/:id" exact component={AskAbout} />
        <PrivateRoute path="/courses/mycourses" exact component={MyCourses} />
        <PrivateRoute path="/courses/recommend" exact component={Recommend} />
        <PrivateRoute path="/courses/recent" exact component={Recent} />
        <PrivateRoute path="/courses/publish" exact component={Publish} />
        {/** For search my course using category name */}
        <PrivateRoute
          path="/courses/mycourses/category/:id"
          exact
          component={MyCourses}
        />
        <PrivateRoute
          path="/courses/explore"
          exact
          component={ExploreCourses}
        />
        <PrivateRoute
          path="/courses/explore/category/:id"
          exact
          component={ExploreCourses}
        />
        <PrivateRoute
          path="/courses/watchhistory"
          exact
          component={WatchCourseHistory}
        />
        <PrivateRoute
          path="/courses/mycourses/createcourse"
          exact
          component={CreateCourse}
        />

        <PrivateRoute
          path="/courses/mycourses/editcourse/:id"
          exact
          component={CreateCourse}
        />
        <PrivateRoute
          path="/courses/mycourses/coursedetail/:id"
          exact
          component={CourseDetail}
        />
        <PrivateRoute path="/myprofile" exact component={MyProfile} />
        <PrivateRoute path="/profile/:id" exact component={MyProfile} />
        <PrivateRoute path="/myprofile/edit" exact component={EditProfile} />
      </Switch>
    </>
  );
};
