import React, { useState, useEffect } from "react";
import { FadeLoader } from "react-spinners";
import { getpublishcourses } from "../../Core/coreapicalls";
import { AppContainer } from "../../StyleConfig/Common/CommonStyles";
import Theme from "../../StyleConfig/Theme";
import HomePageCourses from "./HomePageCourses/HomePageCourses";

function Publish() {
  const [publishCourse, setPublishCourse] = useState([]);
  const [loading, setLoading] = useState(false);

  const publishcourseapi = () => {
    setLoading(true);

    const reqBody = {
      token: JSON.parse(localStorage.getItem("token")),
      userid: JSON.parse(localStorage.getItem("userId")),
    };
    getpublishcourses(reqBody)
      .then((data) => {
        setLoading(false);
        if (data.status === true) {
          setPublishCourse(data.data);
        }
      })
      .catch((error) => {
        setLoading(false);

        throw error;
      });
  };

  useEffect(() => {
    publishcourseapi();
  }, []);

  return (
    <AppContainer style={{ marginTop: "80px", height: "100vh" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {loading === false && (
              <HomePageCourses
                course={publishCourse}
                courseCategory={"Publish Courses"}
                courseLoading={loading.homepagecourseloading}
                cardLayoutHeight={""}
              />
            )}
          </div>
        </div>
        {loading && (
          <div className="d-flex justify-center align-middle pt-20">
            <FadeLoader
              color={Theme.LIGHT.INNERLOADER}
              loading={true}
              size={30}
            />
          </div>
        )}
      </div>
    </AppContainer>
  );
}

export default Publish;
