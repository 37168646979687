import React, { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { FadeLoader } from "react-spinners";
import GridListHorizontal from "../../../components/GridListHorizontal";
import Options, { SegmentControl } from "../../../components/Options";
import { useDataLayerValue } from "../../../context/DataLayer";
import { upcominglearning } from "../../../Core/coreapicalls";
import { LoaderContainer } from "../../../StyleConfig/Common/CommonStyles";
import Theme from "../../../StyleConfig/Theme";

const steeperValue = [
  {
    id: 1,
    name: "All Categories",
    apicategory: "",
  },
  {
    id: 2,
    name: "Computer Programming",
    apicategory: "Computer Programming",
  },
  {
    id: 3,
    name: "Music",
    apicategory: "Music",
  },
  {
    id: 4,
    name: "Sport",
    apicategory: "Sport",
  },
];

function Explore() {
  const [{ segmentTabValueForCourse }] = useDataLayerValue();

  const [explore, setExplore] = useState([]);
  const [loading, setLoading] = useState(true);

  const getupcominglearningapi = useCallback((category) => {
    let reqBody = {
      categoryName: category == undefined ? "" : category,
      api_token: "",
      user_id: "",
    };
    setLoading(true);
    upcominglearning(reqBody)
      .then((res) => {
        setLoading(false);
        if (res.status === true) {
          console.log("Check upcoming learning res", res);
          setExplore(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);

        console.log("Check upcoming learning error", err);
      });
  }, []);

  useEffect(() => {
    getupcominglearningapi();
  }, []);

  return (
    <section id="section-explore" className="explore">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ height: "50px" }} />
            <h2 className="text-center">Explore</h2>
            <div style={{ height: "50px" }} />
            <SegmentControl
              data={steeperValue}
              segmentFor="Explore"
              getCourses={getupcominglearningapi}
            />
            <div style={{ height: "50px" }} />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <GridListHorizontal data={explore} loading={loading} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Explore;
