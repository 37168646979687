import React, { useContext } from "react";
import {
  makeStyles,
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Footer from "../Footer/Footer";
import StepConnector from "@material-ui/core/StepConnector";
import clsx from "clsx";
import "./SteeperSection.scss";
import { BackButton, SaveButton } from "../../StyleConfig/Common/CommonStyles";
import { InterestContext } from "../../context/DataLayer";
import { TopRightSnack } from "../AppSnackbar/AppSnackbar";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundColor: "#7FC98E",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#7FC98E",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 0,
    color: "#fff",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#7FC98E",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#7FC98E",
  },
});

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        color: "white",
      },
    },
    MuiStepLabel: {
      label: {
        color: "#325288",
      },
    },
  },
});
function getSteps() {
  return [
    "About Yourself",
    "Area of Interest",
    "Achievements",
    "Upload Profile",
    "Survey",
    "Add Links",
  ];
}

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function SteeperSection({ stepContent, onsubmit, stepValue }) {
  const classes = useStyles();
  const [interest, setInterest] = useContext(InterestContext);
  const [requireInterest, setRequireInterest] = useState(false);
  const [activeStep, setActiveStep] = React.useState(stepValue);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      // console.log("Check Previous active step", prevActiveStep);
      return prevActiveStep + 1;
    });
    if (activeStep === steps.length - 1) {
      onsubmit();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  // For Interest Snackbar
  const handleClose = () => {
    setRequireInterest(false);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          return (
            <Step key={label}>
              <StepLabel
                style={{ cursor: "pointer" }}
                className={theme}
                StepIconComponent={ColorlibStepIcon}
                onClick={() => {
                  if (index === activeStep - 1) {
                    setActiveStep(index);
                  } else {
                    if (activeStep === 1 && interest.length === 0) {
                      setRequireInterest(true);
                    }
                    setTimeout(() => {
                      setRequireInterest(false);
                    }, 3000);
                  }
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            {/* <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button> */}
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>
              {stepContent(activeStep)}
            </Typography>
            <div style={{ height: "10px" }} />
            <div className="flex-row justify-center">
              <BackButton
                disabled={activeStep === 0}
                onClick={handleBack}
                className="btn back__button"
              >
                <h5 className="back__text">Back</h5>
              </BackButton>
              <SaveButton
                disabled={
                  interest.length === 0 && activeStep === 1 ? true : false
                }
                className="btn"
                variant="contained"
                color="green"
                onClick={handleNext}
              >
                {activeStep === steps.length - 1 ? (
                  <h5 className="finish__text">Finish</h5>
                ) : (
                  <h5 className="next__text">Next</h5>
                )}
              </SaveButton>
            </div>
          </div>
        )}
      </div>
      {requireInterest && (
        <TopRightSnack
          snackbarMessage={"Please select interest"}
          handleClose={handleClose}
        />
      )}
      <div style={{ height: "20px" }} />
    </div>
  );
}

export default SteeperSection;
