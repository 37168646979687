import React from "react";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import moment from "moment";
import LinesEllipsis from "react-lines-ellipsis";
import "./NewsFeeds.scss";
import { Cards } from "../../../StyleConfig/Common/CommonStyles";
import IMAGECONFIG from "../../../StyleConfig/MediaImage";
import { Link } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import Theme from "../../../StyleConfig/Theme";

function NewsFeeds({ news, newsLoading }) {
  return (
    <div id={"newsfeedinner"} className="news__container__height">
      {news.map((newsfeeds, index) => {
        console.log("Check News Feeds", newsfeeds);
        return (
          <>
            <Cards theme={"Light"} key={index + "newsfeeds"}>
              <Link
                to={{
                  pathname: `/profile/${parseInt(newsfeeds.author_userid)}`,
                }}
              >
                <div className="flex-row">
                  <div className="d-flex">
                    <img
                      className="authors__profileImg"
                      src={
                        newsfeeds.author_mediaid
                          ? `${IMAGECONFIG.IMAGECONFIG.PROFILE}${newsfeeds.author_mediaid}`
                          : "https://cdn3.iconfinder.com/data/icons/business-round-flat-vol-1-1/36/user_account_profile_avatar_person_student_male-512.png"
                      }
                      alt="profile"
                    />
                  </div>
                  <div className="authordetails__container">
                    <div className="d-flex">
                      <span className="authors__name">
                        {newsfeeds.author_name}
                      </span>
                      <span className="authorspost__duration">
                        - {newsfeeds.flag}
                      </span>
                    </div>
                    <div style={{ height: "5px" }} />
                    <div className="flex-row">
                      <TimelapseIcon
                        style={{
                          color: "#FD7F44",
                          width: "15px",
                          height: "15px",
                        }}
                      />
                      <span
                        className="authorspost__duration"
                        style={{ marginTop: "0px", marginLeft: "4px" }}
                      >
                        {moment.utc(newsfeeds.created_at).fromNow()}
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
              <div style={{ height: "10px" }} />
              {newsfeeds.status_msg === "Course" ? (
                <Link
                  to={{
                    pathname: `/courses/mycourses/coursedetail/${parseInt(
                      newsfeeds.id
                    )}`,
                    state: newsfeeds,
                  }}
                >
                  <div>
                    <span className="authors__news">{newsfeeds.info}</span>
                  </div>
                  <div style={{ height: "10px" }} />
                  <div className="d-flex">
                    {newsfeeds.media_id !== null && (
                      <img
                        loading="lazy"
                        className="authors__newsImage"
                        src={`${IMAGECONFIG.IMAGECONFIG.BANNER}${newsfeeds.media_id}`}
                        alt="News"
                      />
                    )}
                  </div>
                </Link>
              ) : (
                <div>
                  <span className="authors__news">{newsfeeds.info}</span>
                  <div style={{ height: "10px" }} />
                  <div className="d-flex">
                    {newsfeeds.media_id !== null && (
                      <img
                        loading="lazy"
                        className="authors__newsImage"
                        src={`${IMAGECONFIG.IMAGECONFIG.BANNER}${newsfeeds.media_id}`}
                        alt="News"
                      />
                    )}
                  </div>
                </div>
              )}
            </Cards>
          </>
        );
      })}
      {newsLoading && (
        <div className="d-flex justify-center">
          <FadeLoader
            color={Theme.LIGHT.INNERLOADER}
            loading={true}
            size={30}
          />
        </div>
      )}
    </div>
  );
}

export default NewsFeeds;
