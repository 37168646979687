import React from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { fade, makeStyles } from "@material-ui/core/styles";
import { SaveButton } from "../../StyleConfig/Common/CommonStyles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#FFF",
    "&:hover": {},
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    transition: "boxShadow 4s",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "2px solid rgb(100 101 119 / 50%)",
    borderRadius: "4px",
    "&:focus": {
      boxShadow: "0 0 0 0.25rem rgb(100 101 119 / 15%);",
    },
  },
}));

function Search({ searchBy }) {
  const classes = useStyles();

  const onType = (e) => {
    let cancelToken;
    const onsearch = e.target.value;

    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Canceling previous request");
    }
    cancelToken = axios.CancelToken.source();
    console.log("Check Cancel token in search component", cancelToken);
    searchBy(onsearch, cancelToken);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={
          (e) => onType(e)
          // console.log("Search Bar text", searchBy(e.target.value))
        }
      />
    </div>
  );
}

export default Search;

/**
 * element.style {
    width: 100%;
    background-color: white;
    border: 2px solid #9bafcd;
    border-radius: 4px;
    box-shadow: 0 0 0 0.25rem rgb(100 101 119 / 0%);
    0 2 4 0 (184, 184, 184 / 50%)
}
0 0 0 0.25rem rgb(13 110 253 / 25%)
 */
