import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyBWLwD4B1-7NLIX8sMVqxrf46cL-5NHXho",
  authDomain: "skill-ohana.firebaseapp.com",
  projectId: "skill-ohana",
  storageBucket: "skill-ohana.appspot.com",
  messagingSenderId: "928527043526",
  appId: "1:928527043526:web:32cfa9919a4e1818e380c7",
  measurementId: "G-0CNJB7Z25B",
};

// Initialize firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
