import React from "react";
import { Link } from "react-router-dom";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import "./HomePageCourses.scss";
import {
  Cards,
  CourseDescription,
  CourseDuration,
  CourseSectionTitle,
  CourseTitle,
  TagsContainer,
  TagsTitle,
} from "../../../StyleConfig/Common/CommonStyles";
import { DisplayRatings } from "../../../components/Ratings/Ratings";
import moment from "moment";

function HomePageCourses({
  course,
  courseCategory,
  courseLoading,
  cardLayoutHeight,
}) {
  console.log("Check Height", cardLayoutHeight);
  return (
    <div>
      <CourseSectionTitle>{courseCategory}</CourseSectionTitle>
      <div
        className="homepagecourses__container"
        style={{ height: cardLayoutHeight }}
      >
        {course.map((courses, index) => {
          // console.log("Check Home page Courses", courses);
          return (
            <Link
              to={{
                pathname: `/courses/mycourses/coursedetail/${parseInt(
                  courses.id
                )}`,
                state: course,
              }}
            >
              <Cards theme={"Light"} key={index + "courses"}>
                <CourseTitle>{courses.name}</CourseTitle>
                <br />
                <CourseDescription>{courses.info}</CourseDescription>
                <br />
                <Link
                  to={{
                    pathname: `/profile/${parseInt(courses.author_userid)}`,
                  }}
                >
                  <CourseDescription>- {courses.author_name}</CourseDescription>
                </Link>
                <br />
                <DisplayRatings rating={courses.rating} />
                <br />
                <div className="d-flex">
                  <TagsContainer>
                    <Link
                      to={`/courses/explore/category/${courses.cat_id}`}
                      // .replace(/\s+/g, "-")
                      // .toLowerCase()}`}
                    >
                      <TagsTitle key={index + "tag"}>
                        {courses.cat_id}
                      </TagsTitle>
                    </Link>
                  </TagsContainer>
                </div>
                <TimelapseIcon
                  style={{
                    color: "#FD7F44",
                    margin: "5px",
                    width: "15px",
                    height: "15px",
                  }}
                />
                <CourseDuration>
                  {moment(courses.created_at).fromNow()}
                </CourseDuration>
              </Cards>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default HomePageCourses;
